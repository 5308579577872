<template>
    <div class="Result_">
        <header>
            <div class="label_box">
                <span class="label_text label_text_left">配对结果</span>
                <span class="label_text label_text_right" @click="nameMatchingSkip">
                    <i class="hot_img_box">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG637.pic" alt="">
                    </i>
                    <i class="fire_img_box">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG636.pic" alt="">
                    </i>
                    婚姻测算
                </span>
                    <img class="img_box___" src="https://cdn.zhiyileiju.cn/WechatIMG639.png" alt="">
                
            </div>
        </header>
        <section>
            <!-- <div class="nav_box_">
                <nav>
                    <router-link active-class="activateStyle_" :to="'/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/information'" class="nav_text"> 基本信息 <i  class="underline_"></i></router-link >
                    <router-link active-class="activateStyle_" :to="'/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/analysis'" class="nav_text" >配对分析<i  class="underline_"></i></router-link>
                    <router-link active-class="activateStyle_" :to="'/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/suggest'" class="nav_text">老师建议<i  class="underline_"></i></router-link >
                    <router-link active-class="activateStyle_" :to="'/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/increase'" class="nav_text">增运方式<i  class="underline_"></i></router-link >
                </nav>
                <div class="content_box_">
                    <keep-alive>
                        <router-view></router-view>
                    </keep-alive>

                </div>
                <div class="nav_img_box_" @click="calculationOfEmotionalMarriageBannerSkip">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG640.png" alt="">
                </div>
                <div class="paging_box_">
                    <span class="paging_left_bg_ span_bg_box_" @click="paginationcallback('relief')"><span class="span_symbol_">&lt;</span>上一页</span>
                    <span class="paging_text_">{{pagination_}}/4</span>
                    <span class="paging_right_bg_ span_bg_box_" @click="paginationcallback('gagarin')">下一页<span class="span_symbol_">&gt;</span></span>
                </div>
            </div> -->
            
             <div class="info_color_box_"><i class="icon_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG1300.pic" alt=""></i> 恭喜你！成功领取你的配对结果报告。</div>
             <div class="info_text_box_">请仔细查收哈，测试报告公众号领取后可反复查阅哦~</div>
            <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">匹配契合度</div>
                <div class="name_box_">
                    <div class="grade_box_">{{data_list.fallinmatch.score}}</div>
                    <div class="name_left_box name_box_common"><div class="name_text">{{data_list.scwg.self_scwg.name}} <br> <p>男主角</p></div></div>
                    <div class="name_right_box name_box_common"><div class="name_text">{{data_list.scwg.other_scwg.name}} <br> <p>女主角</p></div></div>
                </div>
                <p class="text_">{{data_list.fallinmatch.str}}</p>
                <p class="text_">
                    特别说明:此分数为双方感情契合度参考分数，反
                    应的是测算双方的感情状况，若遇到分数较高或
                    较低，都需要重视经营自己的感情，通过后天努
                    力弥补当前感情契合度的不足，争取有好的结果
                    发生。<br><br>若仍有疑惑，可联系道长为您解答 
                </p>
            </div>
            <div class="content_box_">
                    <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                    <div class="title_bg_text_">男女姓名婚姻五格</div>
                <div class="user_name_text_">男主角：{{data_list.scwg.self_scwg.name}}</div>
                <div class="img_geomantic_box" v-if='man_name_number === 2'>
                    <div class="div_text_box" style="top:1.05rem;left:.4rem">
                        外格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['外']}}</span>
                    </div>
                    <div class="center_box__">
                            <div class="big_box_">
                                <div class="across_"></div>
                                天格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['天']}}</span>
                            </div>
                            <div class="big_box_" style="top:.88rem">
                                <div class="across_"></div>
                                人格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['人']}}</span>
                            </div>
                            <div class="big_box_" style="top:1.76rem">
                                <div class="across_"></div>
                                地格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['地']}}</span>
                            </div>
                    </div>
                        <div class="right_box__" >
                            <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                            <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[0]}}
                            </div>
                            <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                                <!-- <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[1]}} -->
                            </div>
                            <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[1]}}
                            </div>
                            <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                        总格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['总']}}</span>
                    </div>
                        </div>
        

                </div>
                <div class="img_geomantic_box" v-if='man_name_number === 3'>
                    <div class="div_text_box" style="top:1.05rem;left:.4rem">
                        外格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['外']}}</span>
                    </div>
                    <div class="center_box__">
                            <div class="big_box_">
                                <div class="across_"></div>
                                天格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['天']}}</span>
                            </div>
                            <div class="big_box_" style="top:.88rem">
                                <div class="across_"></div>
                                人格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['人']}}</span>
                            </div>
                            <div class="big_box_" style="top:1.76rem">
                                <div class="across_"></div>
                                地格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['地']}}</span>
                            </div>
                    </div>
                        <div class="right_box__" >
                            <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                            <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[0]}}
                            </div>
                            <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[1]}}
                            </div>
                            <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[2]}}
                            </div>
                            <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                        总格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['总']}}</span>
                    </div>
                        </div>
        

                </div>
                <div class="img_geomantic_box" v-if="man_name_number == 4" style="height: 3.6rem;">
                    <div class="div_text_box" style="top:1.55rem;left:.4rem">
                        外格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['外']}}</span>
                    </div>
                    <div class="center_box__">
                            <div class="big_box_" style="top:.46rem">
                                <div class="across_"></div>
                                天格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['天']}}</span>
                            </div>
                            <div class="big_box_" style="top:1.34rem">
                                <div class="across_"></div>
                                人格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['人']}}</span>
                            </div>
                            <div class="big_box_" style="top:2.22rem">
                                <div class="across_"></div>
                                地格
                                <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['地']}}</span>
                            </div>
                    </div>
                        <div class="right_box__" >
                            <div class="img_box__" style="width: 0.5rem;left: 1.35rem;"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                            <div class="big_box_" style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[0]}}
                            </div>
                            <div class="big_box_" style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[1]}}
                            </div>
                            <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[2]}}
                            </div>
                            <div class="big_box_" style="top:2.64rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.self_scwg.name[3]}}
                            </div>
                            <div class="div_text_box" style="left:1.9rem;top:1.44rem">
                        总格
                            <span class="diamond_box">{{data_list.scwg.self_scwg.fiveGirl['总']}}</span>
                    </div>
                        </div>
                </div>
                
                <div class="user_name_text_">女主角：{{data_list.scwg.other_scwg.name}}</div>
                <div class="img_geomantic_box" v-if='girl_name_number === 2'>
                    <div class="div_text_box" style="top:1.05rem;left:.4rem">
                        外格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['外']}}</span>
                    </div>
                    <div class="center_box__">
                            <div class="big_box_">
                                <div class="across_"></div>
                                天格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['天']}}</span>
                            </div>
                            <div class="big_box_" style="top:.88rem">
                                <div class="across_"></div>
                                人格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['人']}}</span>
                            </div>
                            <div class="big_box_" style="top:1.76rem">
                                <div class="across_"></div>
                                地格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['地']}}</span>
                            </div>
                    </div>
                        <div class="right_box__" >
                            <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                            <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[0]}}
                            </div>
                            <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                                <!-- <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[1]}} -->
                            </div>
                            <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[1]}}
                            </div>
                            <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                        总格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['总']}}</span>
                    </div>
                        </div>
        

                </div>
                <div class="img_geomantic_box" v-if='girl_name_number === 3'>
                    <div class="div_text_box" style="top:1.05rem;left:.4rem">
                        外格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['外']}}</span>
                    </div>
                    <div class="center_box__">
                            <div class="big_box_">
                                <div class="across_"></div>
                                天格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['天']}}</span>
                            </div>
                            <div class="big_box_" style="top:.88rem">
                                <div class="across_"></div>
                                人格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['人']}}</span>
                            </div>
                            <div class="big_box_" style="top:1.76rem">
                                <div class="across_"></div>
                                地格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['地']}}</span>
                            </div>
                    </div>
                        <div class="right_box__" v-if='girl_name_number === 3'>
                            <div class="img_box__"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                            <div class="big_box_"  style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[0]}}
                            </div>
                            <div class="big_box_"  style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[1]}}
                            </div>
                            <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[2]}}
                            </div>
                            <div class="div_text_box" style="left:1.9rem;top:1.05rem">
                        总格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['总']}}</span>
                    </div>
                        </div>
                        
                </div>
                <div class="img_geomantic_box" v-if='girl_name_number === 4' style="height: 3.6rem;">
                    <div class="div_text_box" style="top:1.55rem;left:.4rem">
                        外格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['外']}}</span>
                    </div>
                    <div class="center_box__">
                            <div class="big_box_" style="top:.46rem">
                                <div class="across_"></div>
                                天格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['天']}}</span>
                            </div>
                            <div class="big_box_" style="top:1.34rem">
                                <div class="across_"></div>
                                人格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['人']}}</span>
                            </div>
                            <div class="big_box_" style="top:2.22rem">
                                <div class="across_"></div>
                                地格
                                <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['地']}}</span>
                            </div>
                    </div>
                        <div class="right_box__" >
                            <div class="img_box__" style="width: 0.5rem;left: 1.35rem;"><img src="https://cdn.zhiyileiju.cn/WechatIMG545.pic" alt=""></div>
                            <div class="big_box_" style="top:0rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[0]}}
                            </div>
                            <div class="big_box_" style="top:.88rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[1]}}
                            </div>
                            <div class="big_box_" style="top:1.76rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[2]}}
                            </div>
                            <div class="big_box_" style="top:2.64rem;font-size:.48rem;padding-left: .8rem;">
                                <div class="across_"></div>
                                {{data_list.scwg.other_scwg.name[3]}}
                            </div>
                            <div class="div_text_box" style="left:1.9rem;top:1.44rem">
                        总格
                            <span class="diamond_box">{{data_list.scwg.other_scwg.fiveGirl['总']}}</span>
                    </div>
                        </div>
                </div>
            </div>
            <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">男女双方性格</div>
                <div class="gossip_bg_box_">
                    <span class="man_name_ name_text__">{{data_list.scwg.self_scwg.name}} <br> <i class="designation__">男主角</i> </span>
                    <span class="girl_name_ name_text__">{{data_list.scwg.other_scwg.name}} <br><i class="designation__">女主角</i> </span>
                </div>
                <p class="text_">
                    根据你们的姓名五行能量、时间进行起卦，其中
                    体卦代表本人的性格，用卦代表你求测另一半的
                    性格。
                </p>
                <p class="text_"><span class="overstriking_">本人性格分析</span> <br> {{data_list.xg.self}}</p>
                <p class="text_"><span class="overstriking_">另一半性格分析</span> <br>{{data_list.xg.other}}</p>
                <p class="text_">
                    特别说明:若遇到男女双方性格一样，如都是果决
                    或者安于现状的性格，在相处过程中需要学会互
                    相谦让或者是学会突破自我，避免由于性格类似
                    ，引发感情危机。
                </p>
            </div>
            <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">双方感情匹配度分析</div>
                <div class="gossip_bg_box_">
                    <span class="man_name_ name_text__">{{data_list.scwg.self_scwg.name}} <br> <i class="designation__">男主角</i> </span>
                    <span class="girl_name_ name_text__">{{data_list.scwg.other_scwg.name}} <br><i class="designation__">女主角</i> </span>
                </div>
                <p class="text_">
                    根据你们的姓名五行能量、时间进行起卦，其中
                    <span class="text_red__">本卦代表你们双方感情相处的现状</span>，<span class="text_red__">互卦代表你
                    们未来相处的过程</span> 、容易遇到的情感问题，<span class="text_red__">变卦
                    代表你们相处的结果</span>、最终感情的走向。
                </p>
                <h2 class="title_text__">双方感情现状分析</h2>
                <p class="text_" v-html="data_list.fallin_status.one"></p>
                <p class="text_ overstriking_">评分：<span class="score_box_" v-for="(item ,index) in score_arr" :key="index"><i class="star_box_"><img :src="item" alt=""></i></span></p>
                <p class="text_" v-html="data_list.fallin_status.three"></p>

                <h2 class="title_text__">双方未来相处分析</h2>
                <p class="text_" v-html="data_list.comego_status.one"></p>
                <p class="text_ overstriking_">评分：<span class="score_box_" v-for="(item ,index) in score_arr2" :key="index"><i class="star_box_"><img :src="item" alt=""></i></span></p>
                <p class="text_" v-html="data_list.comego_status.three"></p>

                <h2 class="title_text__">双方最终感情走向</h2>
                <p class="text_" v-html="data_list.future_status.one"></p>
                <p class="text_ overstriking_">评分：<span class="score_box_" v-for="(item ,index) in score_arr3" :key="index"><i class="star_box_"><img :src="item" alt=""></i></span></p>
                <p class="text_" v-html="data_list.future_status.three"></p>
                <p class="text_">
                    温馨提示:若本卦、互卦和变卦三个卦象结果相同
                    ，说明你们双方感情未来的走向与当前感情现状
                    大体一致，不会有太多变化。
                </p>
            </div>
            <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">结婚禁忌提醒</div>
                <p class="text_" >
                    <span class="overstriking_ text_red__">一、婚嫁择日的禁忌</span> <br>
                    1、在挑选结婚吉日的时候不能选择3、7、9月，因为3月包含清明，7月包含盂兰，9月包含重公，这几个日子在中国都是属于“鬼节”，因此不适合结婚。<br>
                    2、每个月的初三、初七、十三、十八、廿二、廿七是传说中三娘煞的日子，这几天三娘会专门破坏新人结婚，因此也不能选。<br>
                    3、选择的结婚吉日不能和新郎新娘的父母生辰相重合。<br>
                    4、选择的结婚吉日不能和新郎新娘的属相冲突
                </p>
                <p class="text_" >
                    <span class="overstriking_ text_red__">二、订婚时的禁忌</span> <br>
                    1、订婚的时候双方一定不能说不吉利的话，例如“再见”“顺走”等等，因为这代表着新郎新娘可能会有再婚的情况，十分不吉利。<br>
                    2、订婚的时候不能将雨伞和扇子带进婚宴场地，因为其代表的是“分散”的寓意。<br>
                    3、男方送聘礼的时候数目切忌不能是单数，结婚习俗中双数有着好事成双的含义，而单数是不吉利的象征。
                </p>
                <p class="text_" >
                    <span class="overstriking_ text_red__">三、婚前准备的禁忌</span> <br>
                    1、婚礼场地选择不能在法院、检察院、警察局、医院等附近，因为这些地方是非多不吉祥。<br>
                    2、婚礼场地的大门不能朝北和朝西开，因为背阳阴气非常重。<br>
                    3、在婚礼前一天，新郎不可一个人单独睡新的婚床，这样有婚后独孤的寓意，因此要选择一名男童晚上陪同。
                </p>
                <p class="text_" >
                    <span class="overstriking_ text_red__">四、结婚当天的禁忌</span> <br>
                    1、男方去接亲的时候，姑姑和姨不能随同。因此姑谐音“孤”，姨谐音“疑”，寓意不吉利。<br>
                    2、新娘结婚当天的衣服不能是带有口袋的，因为这会带走娘子的财运。<br>
                    3、新娘在接亲离开娘家的时候不能不哭，哭的越厉害以后才会越来越有钱。<br>
                    4、结婚当天无论新娘新郎有多累都不能躺下，不然会有常年生病的寓意。<br>
                    5、新娘进男方家门的时候对于门槛一定是要跨过去而不是踩上去。<br>
                    6、新郎在婚礼当天送去的喜饼都是给宾客的，新郎新娘是不能吃的，自己吃掉喜气是不吉利的。
                </p>
                <p class="text_" >
                    以上就是结婚禁忌讲究的全部内容了，不同的地
                    方虽然会有一些额外的禁忌，但是大体上都是一
                    样的。相信只要你做好万全的婚前准备，那么一
                    定能够举办一场不留遗憾的婚礼的!
                </p>
            </div>
            <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">你的财运指数（赠）</div>
                <p class="text_" v-html="data_list.money_status"></p>
                <p class="text_" style="margin-top:0"></p>
            </div>
             <div class="content_box_">
                <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
                <div class="title_bg_text_">双方婚后生活</div>
                <p class="text_" >
                    <span class="overstriking_ text_red__">婚姻格言</span> <br>
                    1、婚姻就是把爱情落实到生活里，睁开一只眼看清楚对方的优点，闭上一只眼无视对方的缺点。在婚姻中学着做个合适的人，而不是去找个合适的人。<br>
                    2、夫妻相处之道是重视及感谢对方所做的一切不要凡事视为当然。<br>
                    3、能有智慧建立一个温馨美满家庭的人，才算是一个真正成功的人。<br>
                    4、没有100分的另一半，只有50分的两个人。<br>
                    5、两个人的幸福需要共同维护才能长久，结果仅供娱乐和参考。
                </p>
                <p class="text_" style="margin-top:0">
                </p>
            </div>

            <div class="section_bottom_text_box_">
            您已看完报告所有内容，如果还有其他疑问
            </div>
            <div class="section_bottom_text_box_">
            可添加老师微信继续咨询哦~
            </div>

            <div class="teacher_consult_img_box_ animation_scaling" @click="consultingTeacherSkip('1')">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1312.png" alt="">
            </div>

            <!-- 热门推荐 -->
            <div class="hot_recommend_box_" id="hotRecommend">
                <h1 class="h1_title_">热门推荐</h1>
                <div class="img_card_box_" @click="cardSkip('1')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG675.png" alt="">
                    <!-- <h2 class="card_text_"> 婚姻运势详批  <span class="span_color_">231654人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_" @click="cardSkip('2')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG1459.png" alt="">
                    <!-- <h2 class="card_text_"> 姻缘分析  <span class="span_color_">326584人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_" @click="cardSkip('3')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG677.png" alt="">
                    <!-- <h2 class="card_text_"> 姓名测试打分  <span class="span_color_">236564人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_ advisory_teacher_" @click="consultingTeacherSkip('2')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG631.png" alt="">
                </div>
            </div>
        
        </section>
        <footer v-if="true">
            <div class="bottom_buttom_box">
                <span class="left_box span_" @click="anchorPoint">更多测试</span>
                <span class="right_box span_" @click="relationshipTitTesting">
                    <i class="icon_img_box_">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG628.pic" alt="">
                    </i>
                    测算我的婚姻运势
                </span>
            </div>
        </footer> 
        <div class="fixation_box_">
            <div class="img_box_" @click="rightFixationSkip('1')"><img src="https://cdn.zhiyileiju.cn/WechatIMG641.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('2')"><img src="https://cdn.zhiyileiju.cn/WechatIMG1455.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('3')"><img src="https://cdn.zhiyileiju.cn/WechatIMG643.png" alt=""></div>
        </div>
        <div class="complaint_box_" @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG562.png" alt="">
        </div>
        <div class="manual_service_shade" @click="manualServicePopupClose" v-if="manual_service_shade_fal"></div>
        <div class="manual_service" v-if="manual_service_shade_fal"><img src="https://cdn.zhiyileiju.cn/WechatIMG1463.jpeg" alt=""></div>
    </div>
</template>
<script>
import { $orderNameDetail } from "../../api/home_marriage_test"
import { $stat } from "../../api/stat"
export default {
    data () { 
        return{
            fixedbtn:false,
            pagination_:1,//当前页数
            manual_service_shade_fal:false,
             data_list:{
                scwg:{
                    self_scwg:{
                        fiveGirl:{},
                        name:''
                    },
                    other_scwg:{
                        fiveGirl:{},
                        name:''
                    }
                },
                fallinmatch:{
                    str:'',
                    score:''
                },
                xg:{
                    self:'',
                    other:''
                },
                fallin_status:{
                    one:'',
                    three:'',
                    two:''
                },
                comego_status:{
                    one:'',
                    three:'',
                    two:''
                },
                future_status:{
                    one:'',
                    three:'',
                    two:''
                },
                money_status:''
                
            },
            score_arr:[
                // 'https://cdn.zhiyileiju.cn/WechatIMG551.png',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
            ],
            score_arr2:[
                // 'https://cdn.zhiyileiju.cn/WechatIMG551.png',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
            ],
            score_arr3:[
                // 'https://cdn.zhiyileiju.cn/WechatIMG551.png',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
                'https://cdn.zhiyileiju.cn/WechatIMG553.pic',
            ],
            man_name_number:3,
            girl_name_number:3,
        }
    },
    watch: {
    '$route': function() {
        this.pagination_ = this.$route.meta.pagination
    }
    },
    created() {
        $stat(`v1_name_matching_result`);
        window.addEventListener("scroll", this.handleScroll);
        this.pagination_ = this.$route.meta.pagination
        this.orderNameDetailDataRequest()
    },
    methods:{
       handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
         orderNameDetailDataRequest() {//用户信息接口
            let dataRequest_data_ = {
                order_id: this.$route.params.id,
                name_id: 0,
            }
            $orderNameDetail(dataRequest_data_).then( ( res ) => {//详情数据
                if ( res.status === 200) {
                    console.log('res',res.data.data)
                    this.data_list = res.data.data.namePairData
                    //男女名字个数
                    this.man_name_number = res.data.data.namePairData.scwg.self_scwg.name.length;
                    this.girl_name_number = res.data.data.namePairData.scwg.other_scwg.name.length;
                    for ( let i = 0; i < res.data.data.namePairData.fallin_status.two; i++ ) { //评分1 心
                        this.score_arr.splice(i,1,'https://cdn.zhiyileiju.cn/WechatIMG551.png')
                    }
                    for ( let i = 0; i < res.data.data.namePairData.comego_status.two; i++ ) { //评分2 心
                        this.score_arr2.splice(i,1,'https://cdn.zhiyileiju.cn/WechatIMG551.png')
                    }
                    for ( let i = 0; i < res.data.data.namePairData.future_status.two; i++ ) { //评分3 心
                        this.score_arr3.splice(i,1,'https://cdn.zhiyileiju.cn/WechatIMG551.png')
                    }
                }
                
            })
        },

        nameMatchingSkip() { 
            $stat("v1_name_matching_result_top_buttom")
            window.location.href="https://jx2.gyqingqing.com/wechatmerry?fromk=dbhy"
        },
        calculationOfEmotionalMarriageBannerSkip() {//感情合婚测算 banner
            window.location.href="https://jx2.gyqingqing.com/wechatmerry?fromk=zbhy"
        },
        paginationcallback(params){//翻页
            if( params === 'relief' ) { //上一页
                this.pagination_--
            }else if( params === 'gagarin' ) { //下一页
                 this.pagination_++
            }
            
            if ( this.pagination_ < 1 ) {
                this.pagination_ = 4
            }else if ( this.pagination_ > 4) {
                this.pagination_ = 1
            }
            switch (this.pagination_) {
                case 1:
                    this.$router.push('/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/information')
                    break;
                case 2:
                    this.$router.push('/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/analysis')
                    break;
                case 3:
                    this.$router.push('/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/suggest')
                    break;
                case 4:
                    this.$router.push('/name_matching/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/increase')
                    break;
            
                default:
                    break;
            }
        },
        //热门推荐
        cardSkip(type) {//男女姓名配对分析
            $stat(`v1_name_matching_result_card_${type}`)
            switch (type) {
                case '1':
                    window.location.href="https://jx2.gyqingqing.com/wechatmerry?fromk=rmhy1"
                    break;
                case '2':
                    window.location.href="https://jx2.gyqingqing.com/wechatceyun?fromk=rmhy2"
                    break;
                case '3':
                    window.location.href="https://jx2.gyqingqing.com/wechattestname?fromk=rmcs"
                    break;
            
                default:
                    break;
            }
        },
        consultingTeacherSkip(id) {// 咨询老师按钮
            $stat(`v1_name_matching_result_center_consult_buttom_${id}`)
            this.manual_service_shade_fal = true
            // this.$router.push('/mini_program_page/result?qr=1')
        },
        anchorPoint () {//锚点
            $stat(`v1_name_matching_result_top_left_buttom`)
            document.querySelector("#hotRecommend").scrollIntoView({behavior: "smooth", block: "start" });
        },
        relationshipTitTesting () {//测算我的婚姻运势
            $stat(`v1_name_matching_result_top_right_buttom`)
            window.location.href="https://jx2.gyqingqing.com/wechatmerry?fromk=dghy"
        },
        rightFixationSkip(type) {//右边屏幕固定按钮
            $stat(`v1_name_matching_result_right_fixation_buttom_${type}`)
            switch (type) {
                case '1':
                    window.location.href="https://jx2.gyqingqing.com/wechatmerry?fromk=xfhy"
                    break;
                case '2':
                    window.location.href="https://jx2.gyqingqing.com/wechatceyun?fromk=xfhy2"
                    break;
                case '3':
                    window.location.href="https://jx2.gyqingqing.com/wechattestname?fromk=xfcs"
                    break;
            
                default:
                    break;
            }
        },
        complaintButtom () {//投诉
            $stat("v1_name_atching_result_complaint");
            window.location.href = 'https://www.wjx.top/vj/hd0JNnc.aspx'
        },
        manualServicePopupClose () {//关闭弹窗
            this.manual_service_shade_fal = false
        },
    }
}
</script>
 <style lang="scss" scoped>
    .Result_{
        header{
            position: fixed;
            top: 0;
            width: 7.5rem;
            z-index: 2;
            .label_box{
                position: relative;
                width: 100%;
                height:.8rem;
                .label_text{
                    display: inline-block;
                    width: 50%;
                    height:.8rem;
                    line-height: .8rem;
                    font-size: .32rem;
                    font-weight: bold;
                    text-align: center;
                }
                .label_text_left{
                    float: left;
                    color: #FFFFFF;
                    background: #C91C18;
                }
                .label_text_right{
                    position: relative;
                    float: right;
                    box-sizing: border-box;
                    border: 1px solid #C91C18;
                    color: #C91C18;
                    background: #FFFFFF;
                    .hot_img_box{
                        position: absolute;
                        width: .46rem;
                        right: .74rem;
                        top: -.14rem;
                    }
                    .fire_img_box{
                        position: absolute;
                        width: .24rem;
                        left: .8rem;
                        top: 0;

                    }
                }
                .img_box___{
                    position: absolute;
                    width: 7.5rem;
                    top: 0.8rem;
                    left: 0;
                    z-index: 2;
                }
            }
        }
        section{
            padding-top: 1.2rem;
            padding-bottom: 1.11rem;
            .nav_box_{//导航栏及内容
                nav{
                    position: sticky;
                    top: .8rem;
                    padding-top: .37rem;
                    padding-bottom: .76rem;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    z-index: 1;
                    background: #fff;
                    .nav_text{
                        position: relative;
                        color: #DD5845;
                        font-size: .32rem;
                    }
                    .activateStyle_{
                         .underline_{
                            position: absolute;
                            bottom: -.13rem;
                            left: 9%;
                            width: .98rem;
                            height: .05rem;
                            background: #DD5845;
                        }
                    }
                }
          
                .nav_img_box_{
                    width: 7.50rem;
                    margin-bottom: 0.3rem;
                }
                .paging_box_{
                    position: relative;
                    overflow: hidden;
                    .paging_text_{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        color: #C91C18;
                        font-size: .36rem;
                        // font-weight: bold;
                    }
                    .span_bg_box_{
                        width: 2.32rem;
                        height: .63rem;
                        line-height: .63rem;
                        color: #fff;
                        font-size: .32rem;
                        text-align: center;
                        background: url('https://cdn.zhiyileiju.cn/WechatIMG623.pic') no-repeat;
                        background-size: 100% 100%;
                        .span_symbol_{
                            font-size: .34rem;
                        }
                    }
                    .paging_left_bg_{
                        float: left;
                        margin-left: .7rem;
                        .span_symbol_{
                            margin-right: .08rem;
                        }
                    }
                    .paging_right_bg_{
                        float: right;
                        margin-right: .7rem;
                        .span_symbol_{
                            margin-left: .08rem;
                        }
                    }
                }
            }

            .info_color_box_{
                position: relative;
                width: 5.74rem;
                margin-left: 1.16rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E7010E;
                .icon_img_{
                    position: absolute;
                    width: .32rem;
                    top: 0.05rem;
                    left: -0.5rem;
                }

            }
            .info_text_box_{
                position: relative;
                width: 5.74rem;
                margin-left: 1.16rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #323232;
                margin-bottom: .5rem;
                line-height: .6rem;
            }
                  .content_box_{
                    position: relative;
                    width: 7.18rem;
                    // height: 8.59rem;
                    margin: 0 auto;
                    background: #fff;
                    border-radius: .30rem;
                    box-shadow: 0 0 5px #e8c7d6;
                    padding-top: .24rem;
                    padding-bottom: .6rem;
                    margin-bottom: .47rem;
                    margin-top: 0.1rem;
                    .flower_img_{
                        position: absolute;
                        width: 7.18rem;
                        top: 0;
                    }
                    .title_bg_text_{
                        width: 4.47rem;
                        height: .73rem;
                        margin: 0 auto;
                        text-align: center;
                        color: #C4393E;
                        font-size: .38rem;
                        font-weight: bold;
                        line-height: .73rem;
                        background: url('https://cdn.zhiyileiju.cn/WechatIMG544.jpeg') no-repeat;
                        background-size: 100% 100%;
                    }
                    .name_box_{
                        position: relative;
                        width: 6.27rem;
                        height: 1.41rem;
                        margin: .55rem auto;
                        .grade_box_{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            width: 1.4rem;
                            height: 1.4rem;
                            font-size: .72rem;
                            font-weight: bold;
                            color: #FFA3B2;
                            text-align: center;
                            line-height: 1.4rem;
                            border-radius: 1.4rem;
                            background: #fff;
                        }
                        .name_box_common{
                            width: 50%;
                            height: 1.41rem;
                            .name_text{
                                font-size: .36rem;
                                font-weight: bold;
                                color: #3D659A;
                                padding-left: .28rem;
                                margin-top: .35rem;
                                p{
                                    // font-size: .18rem;
                                    font-size: .23rem;
                                    font-weight: 400;
                                    // margin-top: .18rem;
                                }
                            }
                        }
                        .name_left_box{
                            float: left;
                            border-top-left-radius: .1rem;
                            border-bottom-left-radius: .1rem;
                            background: #C7DFFF;
                        }
                        .name_right_box{
                            float: right;
                            border-top-right-radius: .1rem;
                            border-bottom-right-radius: .1rem;
                            background: #FECCDD;
                            .name_text{
                                text-align: right;
                                padding-right: .28rem;
                                color: #943858;
                            }
                        }
                    }
                    .text_{
                        padding: 0 .5rem;
                        font-size: .28rem;
                        color: #525252;
                        line-height: .48rem;margin-top: .35rem;
                    }
                    .text_:last-child{
                        margin-top: .37rem;
                        color: #67386F;
                    }
                    .overstriking_{
                        font-weight: bold;
                    }
                    .score_box_{
                        display: inline-block;
                        .star_box_{
                            display: inline-block;
                            width: .34rem;
                            margin-right: .08rem;
                        }
                    }
                    .text_red__{
                        color: #C4393E;
                    }
                    
                    .title_text__{
                        font-size: .28rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #C4393E;
                        text-align: center;
                        margin-top: .36rem;
                        margin-bottom: .28rem;
                    }
                    
                    .user_name_text_{
                        padding-left: .37rem;
                        font-size: .32rem;
                        font-weight: bold;
                        color: #525252;
                        margin-top: .86rem;
                    }
                    .user_name_text_:last-child{
                        margin-top: 0;
                    }
                    .img_geomantic_box{
                        position: relative;
                        height: 2.6rem;
                        margin-top: .6rem;
                        .div_text_box{
                            width: 1.2rem;
                            // display: inline-block;
                            position: absolute;
                            height: .49rem;
                            font-size: .28rem;
                            color: #525252;
                            line-height: .49rem;
                        }
                            .diamond_box{
                                display: inline-block;
                                width: .49rem;
                                height: .49rem;
                                line-height: .49rem;
                                text-align: center;
                                color: #fff;
                                background: #D74A50;
                            }
                        .big_box_{
                            position: absolute;
                            width: 2rem;
                            height: .88rem;
                            font-size: .28rem;
                            color: #525252;
                            line-height: .88rem;
                            box-sizing: border-box;
                            border-left: .05rem solid #D74A50;
                            padding-left: .59rem;
                            .across_{
                                position: absolute;
                                top: 50%;
                                left: -.001rem;
                                transform: translate(0,-50%);
                                width: .44rem;
                                height: .05rem;
                                background: #D74A50;

                            }
                        }
                        .center_box__{
                            position: absolute;
                            left: 1.8rem;
                        }
                        .right_box__{
                            position: absolute;
                            right: 3.3rem;
                            .img_box__{
                                position: absolute;
                                left: 1.45rem;
                                top: .4rem;
                                width: .35rem;
                            }
                            
                        }
                    }
                    .gossip_bg_box_{
                        width: 7.18rem;
                        height: 1.52rem;
                        background: url('https://cdn.zhiyileiju.cn/WechatIMG547.jpeg') no-repeat;
                        background-size: 100% 100%;
                        margin-top: .52rem;
                        margin-bottom: .46rem;
                            text-align: center;
                        .name_text__{
                            color: #525252;
                            font-size: .36rem;
                            margin-top: .84rem;
                            line-height: .35rem;
                            width: 1.7rem;
                            .designation__{
                                display: inline-block;
                                color: #9C9C9C;
                                font-size: .23rem;
                                // transform: scale(1.4);
                            }
                        }
                        .man_name_{
                            float: left;
                            margin-left: .4rem;
                        }
                        .girl_name_{
                            float: right;
                            margin-right: .4rem;
                        }
                    }
                    
                }
            .section_bottom_text_box_{
                text-align: center;
                font-size: .32rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #525252;
                margin-top: .28rem;
                // line-height: 41px;
            }
            .teacher_consult_img_box_{
                width: 6.5rem;
                margin: auto;
                margin-top: .28rem;
                margin-bottom: .3rem;
            }


            .hot_recommend_box_{ //热门推荐
                // margin-top:1.3rem;
                .h1_title_{
                    width: 7.5rem;
                    height: .78rem;
                    font-size: .36rem;
                    font-weight: bold;
                    line-height: .78rem;
                    text-align: center;
                    color: #C91C18;
                    background: rgba(220,87,68,.2);
                    margin-bottom: .48rem;
                }
                .img_card_box_{
                    width: 7.18rem;
                    margin: 0 auto;
                    margin-bottom: .2rem;
                    .card_text_{
                        font-size: .24rem;
                        font-weight: normal;
                        color: #C91C18;
                        .span_color_{
                            color: #525252;
                        }
                    }
                }
                .advisory_teacher_{
                    margin-top: .4rem;
                    margin-bottom: 1.6rem;
                    
                }
            }
        }
        footer{
            overflow: hidden;
            // position: fixed;
            bottom: 0;
            width: 7.5rem;
            // z-index: 2;
            .bottom_buttom_box{
                width: 7.5rem;
                height: 1.11rem;
                .span_{
                    position: relative;
                    height: 1.11rem;
                    line-height: 1.11rem;
                    font-size: .36rem;
                    text-align: center;
                }
                .left_box{
                    float: left;
                    width: 2.19rem;
                    color: #C91C18;
                    box-sizing: border-box;
                    border: .01rem solid #C91C18;
                    background: #fff;
                }
                .right_box{
                    float: right;
                    width: 5.31rem;
                    color: #fff;
                    background: #C91C18;
                    .icon_img_box_{
                        display: inline-block;
                        width: .34rem;
                    }
                }
            }
        }

        .fixation_box_{
            position: fixed;
            top: 3rem;
            right: 0;
            width: .87rem;
            .img_box_{
                width: .87rem;
                margin-top: -0.15rem;
            }
        }
        .complaint_box_{
            position: fixed;
            top: 8.1rem;
            right: 0;
            width: .48rem;
        }

        .manual_service{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 5.14rem;
            z-index: 11;
        }
        //遮罩
        .manual_service_shade{
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background:  rgba(0,0,0,.5);
            z-index: 10;
        }
    }
 </style>